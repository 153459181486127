import styled from 'styled-components';
import { UIButton } from '../../../UI';

export const NegativeResult = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  padding-top: 65px;
  text-align: center;
  position: relative;
  align-items: center;
  padding-bottom: 65px;
  flex-direction: column;
`;

export const TextSupport = styled.span`
  display: block;
  font-size: 14px;
  margin-top: 30px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;

export const Result = styled.span`
  font-size: 18px;
  margin-top: 5px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`;

export const SearchButton = styled(UIButton)`
  left: 0;
  bottom: 0;
  color: #FFF;
  position: absolute;
  margin-bottom: 15px;
  background-color: var(--primary-color);
`;
