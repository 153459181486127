/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import Fuse from 'fuse.js';
import { useParams } from 'react-router';
import { Sticky } from 'react-sticky';
import { SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { addFacebookSearchEvent } from './utils';
import { getOnlyAlphaNums } from '../../../utils/getOnlyAlphaNums';

import * as S from './styles';
import { Lupe } from '../../../assets/svgs/lupe';
import MenuFilterEmptySearch from '../Filter/EmptySearch/EmptySearch';

const MenuNewChangeItemsType = ({
  onSearch,
  customOffset,
  optionsContainerId,
  hasPromotionalItems,
  categoriesWithProducts,
  filteredCategoriesWithProducts,
}) => {
  const stickyRef = useRef(null);
  const { establishment } = useParams();
  const [query, setQuery] = useState('');
  const [swiper, setSwiper] = useState(null);
  const [selectedSlide, setSelectedSlide] = useState(0);

  const onSearchHandler = useCallback(
    ({ currentTarget: { value } }) => {
      const allProducts = categoriesWithProducts.reduce((acc, { products }) => (
        [...acc, ...products]
      ), []);

      setQuery(value);

      const parsedValue = getOnlyAlphaNums(value);
      if (!parsedValue) {
        onSearch(categoriesWithProducts);
        return;
      }

      const options = {
        threshold: 0.4,
        includeScore: true,
        ignoreLocation: true,
        isCaseSensitive: false,
        keys: ['name', 'description'],
        minMatchCharLength: parsedValue.length,
        getFn: (obj, path) => getOnlyAlphaNums(Fuse.config.getFn(obj, path)),
      };
      const fuse = new Fuse(allProducts, options);
      const filteredProducts = fuse.search(parsedValue).map((({ item }) => item));
      const filteredCategories = categoriesWithProducts.map((category) => ({
        ...category,
        products: category.products.filter(
          (product) => filteredProducts.some((fp) => fp.id === product.id),
        ),
      })).filter((category) => category.products.length > 0);

      onSearch(filteredCategories);
    },
    [categoriesWithProducts, onSearch],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (swiper) {
        swiper.slideTo(selectedSlide);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [swiper, selectedSlide]);

  return (
    <>
      <div ref={stickyRef}>
        <Sticky topOffset={105}>
          {({ style, isSticky }) => (
            <S.NewMenuChangeItemType style={{ ...style }}>
              <S.NewMenuChangeItemTypeTop $isSticky={isSticky}>
                <S.DisplaySearch>
                  <h2>Produtos</h2>
                  <S.ContainerSearch>
                    <S.InputSearch
                      type="text"
                      value={query}
                      onChange={onSearchHandler}
                      placeholder="Buscar Produtos"
                      onBlur={() => addFacebookSearchEvent(query)}
                    />
                    <S.LupeStyled />
                  </S.ContainerSearch>
                  <Link className="tw-d-hidden tw-hidden" to={`/${establishment}/buscar`}>
                    <Lupe color="#FFF" />
                  </Link>
                </S.DisplaySearch>
              </S.NewMenuChangeItemTypeTop>
              <S.NewMenuChangeItemTypeItems $isSticky={isSticky}>
                <S.NewMenuChangeItemTypeSlide
                  $isSticky={isSticky}
                  slidesPerView="auto"
                  slideToClickedSlide
                  slidesOffsetAfter={0}
                  onSwiper={(s) => setSwiper(s)}
                >
                  {hasPromotionalItems && (
                    <SwiperSlide>
                      <S.NewMenuChangeItemTypeLink
                        spy
                        smooth
                        duration={500}
                        $isSticky={isSticky}
                        activeClass="active"
                        offset={customOffset}
                        id="custom-promotions"
                        to="custom-promotions"
                        containerId={optionsContainerId || null}
                        onSetActive={() => setSelectedSlide(0)}
                      >
                        Promoções
                      </S.NewMenuChangeItemTypeLink>
                    </SwiperSlide>
                  )}
                  {filteredCategoriesWithProducts && filteredCategoriesWithProducts.length > 0 && (
                    filteredCategoriesWithProducts.map((category, index) => (
                      category.products && category.products.length > 0 ? (
                        <SwiperSlide key={category.id}>
                          <S.NewMenuChangeItemTypeLink
                            spy
                            smooth
                            id={category.name}
                            duration={500}
                            to={category.id.toString()}
                            $isSticky={isSticky}
                            activeClass="active"
                            offset={customOffset}
                            containerId={optionsContainerId || null}
                            onSetActive={() => { setSelectedSlide(index + 1); }}
                          >
                            {category.name}
                          </S.NewMenuChangeItemTypeLink>
                        </SwiperSlide>
                      ) : null
                    ))
                  )}
                </S.NewMenuChangeItemTypeSlide>
              </S.NewMenuChangeItemTypeItems>
            </S.NewMenuChangeItemType>
          )}
        </Sticky>
      </div>

      {filteredCategoriesWithProducts.length === 0 && (
        <MenuFilterEmptySearch search={query} />
      )}
    </>
  );
};

MenuNewChangeItemsType.propTypes = {
  optionsContainerId: PropTypes.string,
  customOffset: PropTypes.number.isRequired,
  hasPromotionalItems: PropTypes.bool.isRequired,
  categoriesWithProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    establishmentId: PropTypes.string,
    name: PropTypes.string,
    sequence: PropTypes.number,
    updatedAt: PropTypes.number,
    createdAt: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      establishmentId: PropTypes.string,
      createdAt: PropTypes.number,
      promotionalPrice: PropTypes.number,
      sequence: PropTypes.number,
      additionals: PropTypes.arrayOf(PropTypes.string),
      price: PropTypes.number,
      updatedAt: PropTypes.number,
      paused: PropTypes.bool,
      description: PropTypes.string,
      priceType: PropTypes.string,
      categoryId: PropTypes.string,
      variations: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        price: PropTypes.number,
        promotionalPrice: PropTypes.number,
      })),
    })),
  })).isRequired,
  filteredCategoriesWithProducts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    establishmentId: PropTypes.string,
    name: PropTypes.string,
    sequence: PropTypes.number,
    updatedAt: PropTypes.number,
    createdAt: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image: PropTypes.string,
      establishmentId: PropTypes.string,
      createdAt: PropTypes.number,
      promotionalPrice: PropTypes.number,
      sequence: PropTypes.number,
      additionals: PropTypes.arrayOf(PropTypes.string),
      price: PropTypes.number,
      updatedAt: PropTypes.number,
      paused: PropTypes.bool,
      description: PropTypes.string,
      priceType: PropTypes.string,
      categoryId: PropTypes.string,
      variations: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        price: PropTypes.number,
        promotionalPrice: PropTypes.number,
      })),
    })),
  })).isRequired,
  onSearch: PropTypes.func.isRequired,
};

MenuNewChangeItemsType.defaultProps = {
  optionsContainerId: '',
};

export default MenuNewChangeItemsType;
