import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { EmptySearchIcon } from '../../../../assets/svgs/EmptySearch';

const MenuFilterEmptySearch = ({ search }) => (
  <S.NegativeResult>
    <EmptySearchIcon />
    <S.TextSupport>Poxa, não encontramos nenhum resultado para</S.TextSupport>
    <S.Result>
      {`"${search}"`}
    </S.Result>
  </S.NegativeResult>
);

MenuFilterEmptySearch.propTypes = {
  search: PropTypes.string,
};

MenuFilterEmptySearch.defaultProps = {
  search: '',
};

export default MenuFilterEmptySearch;
